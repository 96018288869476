import {
    Directive,
    OnInit,
    Input,
    ElementRef,
    inject,
    OnDestroy,
    SimpleChange,
    SimpleChanges,
    OnChanges
} from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { TranslatePipe } from './pipes';
import { HelpersService } from '../shared/services/helpers.service';
import { createPopper } from '@popperjs/core';

@Directive({
    selector: '[legacyLinkInner]',
    standalone: true
})
export class LegacyLinkInnerDirective implements OnInit {
    @Input() legacy: boolean;
    constructor(private el: ElementRef) {}

    ngOnInit() {
        this.el.nativeElement.addEventListener('click', (e: PointerEvent) => {
            if (this.legacy) {
                e.preventDefault();
                e.stopPropagation();
                const parent = this.el.nativeElement.closest('a');
                if (parent?.href) {
                    if (e.ctrlKey || e.metaKey) {
                        window.open(parent.href);
                    } else window.location.href = parent.href;
                }
            }
        });
    }
}

@Directive({
    selector: '[disableOnDownload]',
    standalone: true
})
export class DisableOnDownloadDirective implements OnInit {
    @Input() timeout?: number;
    @Input() toast?: string;

    constructor(private el: ElementRef, private toastrService: ToastrService, private translatePipe: TranslatePipe) {}

    ngOnInit() {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const that = this;
        this.el.nativeElement.addEventListener('click', () => {
            (that.el.nativeElement as HTMLElement).classList.add('disabled');
            (that.el.nativeElement as HTMLElement).setAttribute('disabled', '');
            if (that.toast) setTimeout(() => that.toastrService.success(that.toast), 200);
            setTimeout(() => {
                (that.el.nativeElement as HTMLElement).classList.remove('disabled');
                (that.el.nativeElement as HTMLElement).removeAttribute('disabled');
            }, that.timeout ?? 800);
        });
    }
}

@Directive({
    selector: '[copyOnClick]',
    standalone: true
})
export class CopyOnClickDirective implements OnInit {
    @Input() copy: string;
    @Input() toast?: string;

    helpersService = inject(HelpersService);

    constructor(private el: ElementRef, private toastrService: ToastrService, private translatePipe: TranslatePipe) {}

    ngOnInit() {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const that = this;
        this.el.nativeElement.addEventListener('click', () => {
            that.helpersService.copyToClipboard(that.copy, that.toast);
        });
    }
}

@Directive({
    selector: '[ellipsisTooltip]',
    standalone: true
})
export class EllipsisTooltipDirective implements OnChanges {
    @Input() ellipsisTooltip = '';

    shown = false;
    tooltip: HTMLElement;
    constructor(private el: ElementRef) {}

    ngOnChanges() {
        this.tooltip?.remove();
        this.init();
    }

    init() {
        setTimeout(() => {
            if (!this.ellipsisTooltip) return;
            const el = document.createElement('div');
            el.className = 'ellipsis-tooltip';
            el.innerHTML = `${this.ellipsisTooltip}`;
            document.body.appendChild(el);
            this.tooltip = el;
            // eslint-disable-next-line @typescript-eslint/no-this-alias
            const that = this;

            const popperInstance = createPopper(this.el.nativeElement, <HTMLElement>this.tooltip, {
                placement: 'top',
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [0, 0]
                        }
                    }
                ]
            });

            function show() {
                if (!that.hasEllipsis()) return;
                that.tooltip.setAttribute('data-show', '');
                popperInstance.setOptions((options) => ({
                    ...options,
                    modifiers: [...options.modifiers, { name: 'eventListeners', enabled: true }]
                }));
                popperInstance.update();
                that.shown = true;
            }

            function hide() {
                setTimeout(() => {
                    that.tooltip.removeAttribute('data-show');
                    popperInstance.setOptions((options) => ({
                        ...options,
                        modifiers: [...options.modifiers, { name: 'eventListeners', enabled: false }]
                    }));
                    that.shown = false;
                }, 1);
            }

            const showEvents = ['mouseenter', 'focus'];
            const hideEvents = ['mouseleave', 'blur'];

            showEvents.forEach((event) => {
                this.el.nativeElement.addEventListener(event, show);
            });

            hideEvents.forEach((event) => {
                this.el.nativeElement.addEventListener(event, hide);
            });
        }, 5);
    }

    hasEllipsis() {
        return this.el.nativeElement.offsetWidth < this.el.nativeElement.scrollWidth;
    }

    ngOnDestroy() {
        this.tooltip?.remove();
    }
}
